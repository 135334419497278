

























































































































































































































import { defineComponent, ref } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { mapActions, mapState } from 'vuex'
import { QueryParams } from '@/store/api-plateform-utils'
import { Company } from '@/api/interfaces/company'
import { CompanyContact } from '@/api/interfaces/companyContact'
import {
  usePagination,
  PaginationOptions,
  parseQuery,
} from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import router from '@/router'
import { userConnectedCanAccess } from '@/composables/UserGrant'
import PreCreateCompany from '@/components/molecules/dialog/preCreateCompany.vue'
import Initials from '@/components/molecules/initials.vue'
import { arrayFromUrl } from '@/composables/planning/paramsUrlPlanning'
import ListSelectUserFilter from '@/components/base/ListSelectUserFilter.vue'

export default defineComponent({
  setup (props, ctx) {
    const canAddCompany = () => userConnectedCanAccess('Company Create')
    const query = {
      ...router.app.$route.query,
    }
    const totalItems = () => {
      return ctx.root.$store.state.company.totalItems
    }
    const filters = ref({
      name: query.name || '',
      type: query.type || '',
      siret: query.siret || '',
      mainManagerLastName: query.mainManagerLastName || '',
      mainManagerFirstName: query.mainManagerFirstName || '',
      trainingOfficersDriverProgram: arrayFromUrl(query.trainingOfficersDriverProgram) || [],
      trainingOfficersOthers: arrayFromUrl(query.trainingOfficersDriverProgram) || [],
      commercials: arrayFromUrl(query.trainingOfficersDriverProgram) || [],
    })
    delete query.name
    delete query.type
    delete query.siret
    delete query.mainManagerLastName
    delete query.mainManagerFirstName
    delete query.trainingOfficersDriverProgram
    delete query.trainingOfficersOthers
    delete query.commercials
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(
    totalItems,
    parseQuery(query),
  ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      filters,
      canAddCompany,
    }
  },
  name: 'CompanyList',
  components: {
    ListSelectUserFilter,
    Initials,
    TopBar,
    ListTextFilter: () => import('@/components/base/ListTextFilter.vue'),
    Pagination,
    PreCreateCompany,
  },
  data () {
    return {
      title: this.$t('company.title'),
      headers: [
        {
          text: this.$t('company.list.headers.company'),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$t('company.list.headers.siret'),
          value: 'siret',
        },
        {
          text: this.$t('company.list.headers.type'),
          value: 'type',
        },
        {
          text: this.$t('company.list.headers.manager.lastName'),
          value: 'contactLastName',
          sortable: false,
        },
        {
          text: this.$t('company.list.headers.manager.firstName'),
          value: 'contactFirstName',
          sortable: false,
        },
        {
          text: this.$t('company.list.headers.phone'),
          value: 'phone',
          sortable: false,
        },
        {
          text: this.$t('company.list.headers.email'),
          value: 'email',
          sortable: false,
        },
        {
          text: this.$t('company.list.headers.address'),
          value: 'address',
          sortable: false,
        },
        {
          text: this.$t('company.list.headers.nbTrainee'),
          value: 'countNeedTrainings',
          sortable: false,
        },
        {
          text: this.$t('company.list.headers.training_officers_driver_program'),
          value: 'trainingOfficersDriverProgram',
          sortable: false,
        },
        {
          text: this.$t('company.list.headers.training_officers_others'),
          value: 'trainingOfficersOthers',
          sortable: false,
        },
        {
          text: this.$t('company.list.headers.commercials'),
          value: 'commercials',
          sortable: false,
        },
        {
          text: this.$t('company.list.headers.actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      showModalPreCreateCompany: false,
    }
  },
  computed: {
    ...mapState('company', {
      companyList: 'list',
      loading: 'loading',
    }),
    ...mapState('trainingOfficer', {
      listTrainingOfficersDriverProgram: 'listTrainingOfficersDriverProgram',
      listTrainingOfficersOthers: 'listTrainingOfficersOthers',
      listCommercials: 'listCommercial',
      loadingTrainingOfficersDriverProgram: 'loadingTrainingOfficersDriverProgram',
      loadingTrainingOfficersOthers: 'loadingTrainingOfficersOthers',
      loadingCommercials: 'loadingCommercial',
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        ...this.filters,
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
  },
  async created () {
    await Promise.all([
      this.load(this.baseRequest),
      this.loadTrainingOfficersDriverProgram({ pagination: false }),
      this.loadTrainingOfficersOthers({ pagination: false }),
      this.loadCommercials({ pagination: false }),
    ])
  },
  watch: {
    filters: {
      handler () {
        this.paginationOptions.page = 1
        this.loadData()
      },
      deep: true,
    },
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('company', {
      load: 'load',
    }),
    ...mapActions('trainingOfficer', {
      loadTrainingOfficersDriverProgram: 'loadTrainingOfficersDriverProgram',
      loadTrainingOfficersOthers: 'loadTrainingOfficersOthers',
      loadCommercials: 'loadCommercials',
    }),
    goToCompanyForm (item: Company) {
      if (item.id) {
        this.$router.push({
          name: 'Company Edit',
          params: { idCompany: item.id.toString() },
        })
      }
    },
    loadData () {
      this.addParamsToLocation()
      this.load(this.baseRequest)
    },
    addParamsToLocation () {
      let query: any = {
        ...this.filters,
        ...this.paginationOptions,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => !!value),
      )

      router.replace({ name: 'Company List', query: query })
    },
    getFirstManagerLastName (companyContacts: Array<CompanyContact>) {
      let lastName
      companyContacts.forEach((manager: CompanyContact) => {
        if (manager.main === true) {
          lastName = manager.lastName
        }
      })

      return lastName
    },
    getFirstManagerFirstName (companyContacts: Array<CompanyContact>) {
      let firstName
      companyContacts.forEach((manager: CompanyContact) => {
        if (manager?.main === true) {
          firstName = manager.firstName
        }
      })

      return firstName
    },
    filterByName (value: string) {
      this.filters.name = value
    },
    filterBySiret (value: string) {
      this.filters.siret = value
    },
    filterByType (value: string) {
      this.filters.type = value
    },
    filterByMainManagerLastName (value: string) {
      this.filters.mainManagerLastName = value
    },
    filterByMainManagerFirstName (value: string) {
      this.filters.mainManagerFirstName = value
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'Company Edit' || to.name === 'Company Create') {
      this.$store.commit('navigation/resetPile')
      this.$store.commit('navigation/addRoute', from)
    }
    next()
  },
})
