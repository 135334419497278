







































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  name: 'PreCreateCompany',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      siret: '',
      isLoading: false,
    }
  },
  computed: {
    ...mapState('apiCompany', {
      infoLegales: 'infoLegales',
      contact: 'contact',
    }),
  },
  methods: {
    ...mapActions('apiCompany', {
      loadCompanyFromSiret: 'loadCompanyFromSiret',
    }),
    ...mapActions('companyContactList', {
      resetCompanyContacts: 'reset',
    }),
    ...mapActions('companyForm', {
      reset: 'reset',
      setCompanyFromApi: 'setCompanyFromApi',
    }),
    async searchCompany () {
      try {
        this.isLoading = true
        this.reset()
        this.resetCompanyContacts()
        await this.loadCompanyFromSiret(this.siret.replaceAll(' ', ''))
        this.setCompanyFromApi({
          infoLegales: this.infoLegales,
          contact: this.contact,
        })
      } finally {
        this.isLoading = false
        this.goToFormCompany()
      }
    },
    goToFormCompanyWithoutSearch () {
      this.reset()
      this.resetCompanyContacts()
      this.goToFormCompany()
    },
    goToFormCompany () {
      this.$router.push({ name: 'Company Create' })
    },
    close () {
      this.siret = ''
      this.$emit('close')
    },
  },
})
